import { Box, Text, Title } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import StrongAuthComp from '../../../components/Auth/StrongAuth';
import { useAppSelector } from '../../../hooks/store';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useProfileLandingSaveStrongAuthMutation } from '../../../store/api/profileLandingApi';
import { setBirthDate, setNames, setPersonalCode, setTempToken } from '../../../store/profileLandingSlice';
import { decodeTempToken } from '../../../store/profileLandingSlice';

const StrongAuth = (props: { nextStep: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [saveStrongAuth, saveStrongAuthQueryState] = useProfileLandingSaveStrongAuthMutation();
  const tempToken = useAppSelector((state) => state.profileLanding.tempToken);

  const handleFullAuth = () => {
    toast(t('profileLanding.strongAuthStep.accountAlreadyExistsError'), { type: 'error' });
  };

  useEffect(() => {
    if (saveStrongAuthQueryState.isSuccess) {
      dispatch(setTempToken(saveStrongAuthQueryState.data?.tempToken));
      const { personalCode, firstName, lastName, birthDate } = decodeTempToken(
        saveStrongAuthQueryState.data?.tempToken ?? '',
      );
      dispatch(setPersonalCode(personalCode));
      dispatch(setBirthDate(birthDate));
      dispatch(setNames({ firstName: firstName, lastName: lastName }));
      saveStrongAuthQueryState.reset();
      props.nextStep();
    }
  }, [saveStrongAuthQueryState.isSuccess, props, dispatch, saveStrongAuthQueryState]);

  useEffect(() => {
    if (saveStrongAuthQueryState.isError) {
      toast(t('profileLanding.strongAuthStep.savingError'), { type: 'error' });
      saveStrongAuthQueryState.reset();
    }
  }, [saveStrongAuthQueryState.isSuccess, props, dispatch, saveStrongAuthQueryState, t]);

  const handlePartialAuth = useCallback(
    async (strongAuthTempToken: string) => {
      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.ProfileLandingSaveStrongAuth);
      saveStrongAuth({
        tempToken: tempToken || '',
        recaptchaToken: recaptchaToken,
        strongAuthTempToken: strongAuthTempToken,
      });
    },
    [executeRecaptcha, saveStrongAuth, tempToken],
  );

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {t('profileLanding.strongAuthStep.title')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>{t('profileLanding.strongAuthStep.description')}</Text>
      </Text>
      <Box>
        <StrongAuthComp
          partialAuth={handlePartialAuth}
          fullAuth={handleFullAuth}
          titleContent={null}
          footerContent={<></>}
        ></StrongAuthComp>
      </Box>
    </Box>
  );
};

export default StrongAuth;
