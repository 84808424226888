import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Connector from './connector';
import Step, { StepState } from './step';

interface ProgressProps {
  currentStep: number;
  skipContactVerification: boolean;
  skipStrongAuth: boolean;
}

const getState = function (step: number, currentStep: number): StepState {
  if (step == currentStep) return StepState.Active;
  if (step < currentStep) return StepState.Complete;

  return StepState.Inactive;
};

const Progress = (props: ProgressProps) => {
  const { t } = useTranslation();
  let number = 1;
  let numberHorizontal = 1;

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          [theme.fn.smallerThan(960)]: {
            display: 'none',
          },
        })}
      >
        <Connector isComplete={props.currentStep >= 1} isHorizontal={false}></Connector>
        <Connector isComplete={props.currentStep >= 1} isHorizontal={false}></Connector>
        <Step
          title={t('profileLanding.progress.language.stepName')}
          number={number++}
          state={getState(1, props.currentStep)}
        />
        <Connector isComplete={props.currentStep >= 2} isHorizontal={false}></Connector>
        {!props.skipStrongAuth ? (
          <>
            <Step
              title={t('profileLanding.progress.identification.stepName')}
              number={number++}
              state={getState(2, props.currentStep)}
            />
            <Connector isComplete={props.currentStep >= 3} isHorizontal={false}></Connector>
          </>
        ) : (
          <></>
        )}
        {!props.skipContactVerification ? (
          <>
            <Step
              title={t('profileLanding.progress.contact.stepName')}
              number={number++}
              state={getState(4, props.currentStep)}
            />
            <Connector isComplete={props.currentStep >= 5} isHorizontal={false}></Connector>
          </>
        ) : (
          <></>
        )}

        <Step
          title={t('profileLanding.progress.profile.stepName')}
          number={number++}
          state={getState(5, props.currentStep)}
        />
        <Connector isComplete={props.currentStep >= 6} isHorizontal={false}></Connector>

        <Connector isComplete={props.currentStep >= 6} isHorizontal={false}></Connector>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          height: '50px',
          [theme.fn.largerThan(960)]: {
            display: 'none',
          },
        })}
      >
        <Step title="" number={numberHorizontal++} state={getState(1, props.currentStep)} />
        {!props.skipStrongAuth ? (
          <>
            <Step title="" number={numberHorizontal++} state={getState(2, props.currentStep)} />
          </>
        ) : (
          <></>
        )}
        {!props.skipContactVerification ? (
          <>
            <Step title="" number={numberHorizontal++} state={getState(3, props.currentStep)} />
          </>
        ) : (
          <></>
        )}
        <Step title="" number={numberHorizontal++} state={getState(4, props.currentStep)} />
      </Box>
    </>
  );
};

export default Progress;
