import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import StrongAuthSwitch from './StrongAuthSwitch';
import Button from '../../components/Button';
import PhoneNumberInput from '../../components/form/PhoneNumberInput';
import TextInput from '../../components/form/TextInput';
import {
  checkIfHasPersonalCode,
  checkIfHasPhoneOrEmail,
  checkIfPersonalCodeIsValid,
  ESTONIAN_MOBILE_NUMBER_REGEX,
  StrongAuthMode,
} from '../../utility';
import InfoBox from '../../components/infoBox';
import {
  useSmartIdStatusMutation,
  useMobileIdStatusMutation,
  useStartMobileIdMutation,
  useStartSmartIdMutation,
  useStartIdCardMutation,
  useIdCardStatusMutation,
} from '../../store/api/strongAuthApi';
import RecaptchaAction from '../../models/RecaptchaAction';
import useAppLogin from '../../hooks/useAppLogin';
import Loader from '../loader';
import PersonalData from '../../models/PersonalData';

interface StrongAuthLoginFormValues {
  authMode: StrongAuthMode;
  personalCode: string;
  phone: string;
}

const StrongAuth = (props: {
  fullAuth: (token: string, personalData: PersonalData) => void;
  partialAuth: (tempToken: string) => void;
  titleContent: React.ReactNode;
  footerContent: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [login] = useAppLogin();
  const [authMode, setAuthMode] = useState(StrongAuthMode.SmartId);
  const [securityCode, setSecurityCode] = useState('');
  const [pollStartTime, setPollStartTime] = useState<Date | null>(null);
  const [tempToken, setTempToken] = useState('');

  const [startMobileId, startMobileIdQueryState] = useStartMobileIdMutation();
  const [startSmartId, startSmartIdQueryState] = useStartSmartIdMutation();
  const [mobileIdStatus, mobileIdStatusQueryState] = useMobileIdStatusMutation();
  const [smartIdStatus, smartIdStatusQueryState] = useSmartIdStatusMutation();
  const [startIdCard, startIdCardQueryState] = useStartIdCardMutation();
  const [idCardStatus, idCardStatusQueryState] = useIdCardStatusMutation();

  const isLoading =
    startMobileIdQueryState.isLoading ||
    mobileIdStatusQueryState.isLoading ||
    startSmartIdQueryState.isLoading ||
    smartIdStatusQueryState.isLoading ||
    startIdCardQueryState.isLoading ||
    idCardStatusQueryState.isLoading ||
    securityCode.length > 0;

  const form = useForm<StrongAuthLoginFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        personalCode: Yup.string()
          .ensure()
          .test('empty-field', t('landing.strongAuthLoginForm.personalCodeRequiredError'), checkIfHasPersonalCode)
          .test(
            'personal-code-valid',
            t('landing.strongAuthLoginForm.personalCodeInvalidError'),
            checkIfPersonalCodeIsValid,
          ),
        phone: Yup.string().when('authMode', {
          is: StrongAuthMode.MobileId,
          then: Yup.string()
            .matches(ESTONIAN_MOBILE_NUMBER_REGEX, t('landing.loginForm.mobileInvalidError'))
            .test('empty-field', t('landing.strongAuthLoginForm.phoneRequiredError'), checkIfHasPhoneOrEmail),
        }),
      }),
    ),
    initialValues: {
      authMode: StrongAuthMode.SmartId,
      personalCode: '',
      phone: '',
    },
  });

  const resetFields = useCallback(() => {
    startSmartIdQueryState.reset();
    smartIdStatusQueryState.reset();
    startMobileIdQueryState.reset();
    mobileIdStatusQueryState.reset();
    setSecurityCode('');
    form.clearErrors();
  }, [
    form,
    mobileIdStatusQueryState,
    smartIdStatusQueryState,
    startMobileIdQueryState,
    startSmartIdQueryState,
    setSecurityCode,
  ]);

  useEffect(() => {
    if (startMobileIdQueryState.isSuccess && startMobileIdQueryState.data.controlCode) {
      setSecurityCode(startMobileIdQueryState.data.controlCode);
    }
  }, [startMobileIdQueryState.isSuccess, startMobileIdQueryState.data?.controlCode]);

  useEffect(() => {
    if (startSmartIdQueryState.isSuccess && startSmartIdQueryState.data.controlCode) {
      setSecurityCode(startSmartIdQueryState.data.controlCode);
    }
  }, [startSmartIdQueryState.isSuccess, startSmartIdQueryState.data?.controlCode]);

  useEffect(() => {
    let timeout = 0;
    const now = new Date();
    const timeFromAuthStartMs = pollStartTime ? now.getTime() - pollStartTime.getTime() : 0;
    const twoMinutesMs = 2 * 60 * 1000;

    if (smartIdStatusQueryState.isSuccess && timeFromAuthStartMs > twoMinutesMs) {
      toast(t('landing.strongAuthLoginForm.smartIdTimeout'), { type: 'info' });
      setSecurityCode('');
      return;
    }

    if (smartIdStatusQueryState.isSuccess && smartIdStatusQueryState.data.status == 'canceled') {
      toast(t('landing.strongAuthLoginForm.smartIdCancelled'), { type: 'info' });
      clearTimeout(timeout);
      setSecurityCode('');
      return;
    }

    if (
      smartIdStatusQueryState.isError ||
      (smartIdStatusQueryState.isSuccess && smartIdStatusQueryState.data.status == 'wrong_vc')
    ) {
      toast(t('landing.strongAuthLoginForm.smartIdFailed'), { type: 'error' });
      clearTimeout(timeout);
      setSecurityCode('');
      startSmartIdQueryState.reset();
      smartIdStatusQueryState.reset();
      return;
    }

    if (smartIdStatusQueryState.isSuccess && smartIdStatusQueryState.data.status == 'ok') {
      clearTimeout(timeout);

      if (smartIdStatusQueryState.data?.token && smartIdStatusQueryState.data.personalData) {
        props.fullAuth(smartIdStatusQueryState.data.token, smartIdStatusQueryState.data.personalData);
        resetFields();
        return;
      } else if (smartIdStatusQueryState.data?.tempToken) {
        props.partialAuth(smartIdStatusQueryState.data?.tempToken);
        resetFields();
        return;
      }

      smartIdStatusQueryState.reset();
    }

    if (startSmartIdQueryState.isSuccess && timeFromAuthStartMs < twoMinutesMs && !smartIdStatusQueryState.isLoading) {
      timeout = window.setTimeout(async () => {
        const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StrongAuthStatus);
        smartIdStatus({ token: startSmartIdQueryState.data?.token, recaptchaToken: recaptchaToken });
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    props,
    startSmartIdQueryState.isSuccess,
    startSmartIdQueryState.data?.token,
    executeRecaptcha,
    login,
    navigate,
    pollStartTime,
    smartIdStatus,
    smartIdStatusQueryState,
    smartIdStatusQueryState.data?.personalData,
    smartIdStatusQueryState.data?.status,
    smartIdStatusQueryState.data?.token,
    smartIdStatusQueryState.isLoading,
    smartIdStatusQueryState.isSuccess,
    smartIdStatusQueryState.data?.tempToken,
    smartIdStatusQueryState.data?.personalCode,
    smartIdStatusQueryState.data?.name,
    smartIdStatusQueryState.data?.surname,
    startMobileIdQueryState.data?.tempToken,
    smartIdStatusQueryState.data?.birthDate,
    form.values.personalCode,
    dispatch,
    t,
    startSmartIdQueryState,
    resetFields,
  ]);

  useEffect(() => {
    let timeout = 0;
    const now = new Date();
    const timeFromAuthStartMs = pollStartTime ? now.getTime() - pollStartTime.getTime() : 0;
    const twoMinutesMs = 2 * 60 * 1000;

    if (mobileIdStatusQueryState.isSuccess && timeFromAuthStartMs > twoMinutesMs) {
      toast(t('landing.strongAuthLoginForm.mobileIdTimeout'), { type: 'info' });
      setSecurityCode('');
      return;
    }

    if (mobileIdStatusQueryState.isSuccess && mobileIdStatusQueryState.data.status == 'canceled') {
      toast(t('landing.strongAuthLoginForm.mobileIdCancelled'), { type: 'info' });
      clearTimeout(timeout);
      setSecurityCode('');
      return;
    }

    if (mobileIdStatusQueryState.isError) {
      toast(t('landing.strongAuthLoginForm.mobileIdFailed'), { type: 'error' });
      clearTimeout(timeout);
      setSecurityCode('');
      startMobileIdQueryState.reset();
      mobileIdStatusQueryState.reset();
      return;
    }

    if (mobileIdStatusQueryState.isSuccess && mobileIdStatusQueryState.data.status == 'ok') {
      clearTimeout(timeout);

      if (mobileIdStatusQueryState.data?.token && mobileIdStatusQueryState.data.personalData) {
        props.fullAuth(mobileIdStatusQueryState.data?.token, mobileIdStatusQueryState.data.personalData);
        resetFields();
        return;
      } else if (mobileIdStatusQueryState.data?.tempToken) {
        props.partialAuth(mobileIdStatusQueryState.data?.tempToken);
        resetFields();
        return;
      }

      mobileIdStatusQueryState.reset();
    }

    if (
      startMobileIdQueryState.isSuccess &&
      timeFromAuthStartMs < twoMinutesMs &&
      !mobileIdStatusQueryState.isLoading
    ) {
      timeout = window.setTimeout(async () => {
        const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StrongAuthStatus);
        mobileIdStatus({
          token: startMobileIdQueryState.data?.token,
          recaptchaToken: recaptchaToken,
          tempToken: tempToken,
        });
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    props,
    startMobileIdQueryState.isSuccess,
    startMobileIdQueryState.data?.token,
    executeRecaptcha,
    login,
    navigate,
    pollStartTime,
    mobileIdStatus,
    mobileIdStatusQueryState,
    mobileIdStatusQueryState.data?.personalData,
    mobileIdStatusQueryState.data?.status,
    mobileIdStatusQueryState.data?.token,
    mobileIdStatusQueryState.isLoading,
    mobileIdStatusQueryState.isSuccess,
    mobileIdStatusQueryState.data?.tempToken,
    mobileIdStatusQueryState.data?.personalCode,
    mobileIdStatusQueryState.data?.name,
    mobileIdStatusQueryState.data?.surname,
    startMobileIdQueryState.data?.tempToken,
    mobileIdStatusQueryState.data?.birthDate,
    form.values.personalCode,
    form.values.phone,
    dispatch,
    t,
    tempToken,
    startMobileIdQueryState,
    resetFields,
  ]);

  useEffect(() => {
    if (startSmartIdQueryState.isError) {
      toast(t('landing.strongAuthLoginForm.smartIdFailed'), { type: 'error' });
      startSmartIdQueryState.reset();
    }
  }, [startSmartIdQueryState.isError, t, startSmartIdQueryState]);

  useEffect(() => {
    if (startMobileIdQueryState.isError) {
      toast(t('landing.strongAuthLoginForm.mobileIdFailed'), { type: 'error' });
      startMobileIdQueryState.reset();
    }
  }, [startMobileIdQueryState.isError, t, startMobileIdQueryState]);

  useEffect(() => {
    if (startIdCardQueryState.isSuccess && startIdCardQueryState.data?.sessionToken) {
      const options: DokobitOptions = {
        sessionToken: startIdCardQueryState.data.sessionToken,
        callback: async function (returnToken) {
          const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.IdCardStatus);
          idCardStatus({ token: returnToken, recaptchaToken: recaptchaToken });
        },
        locale: i18n.language as DokobitOptions['locale'],
        primaryColor: '#195096',
        container: '#Dokobit-identity-container',
      };
      new DokobitIdentity(options).init();
      startIdCardQueryState.reset();
    }
  }, [
    startIdCardQueryState.data,
    startIdCardQueryState.isSuccess,
    executeRecaptcha,
    idCardStatus,
    i18n.language,
    startIdCardQueryState,
  ]);

  useEffect(() => {
    if (idCardStatusQueryState.isSuccess) {
      if (idCardStatusQueryState.data?.token && idCardStatusQueryState.data.personalData) {
        props.fullAuth(idCardStatusQueryState.data.token, idCardStatusQueryState.data.personalData);
        return;
      } else if (idCardStatusQueryState.data?.tempToken && idCardStatusQueryState.data?.personalCode) {
        props.partialAuth(idCardStatusQueryState.data?.tempToken);
        return;
      }

      idCardStatusQueryState.reset();
    }
  }, [
    dispatch,
    idCardStatusQueryState.data?.birthDate,
    idCardStatusQueryState.data?.name,
    idCardStatusQueryState.data?.personalCode,
    idCardStatusQueryState.data?.personalData,
    idCardStatusQueryState.data?.surname,
    idCardStatusQueryState.data?.tempToken,
    idCardStatusQueryState.data?.token,
    idCardStatusQueryState.isSuccess,
    props,
    idCardStatusQueryState,
  ]);

  const handlePersonalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('personalCode', e.target.value);
    if (form.errors.personalCode) {
      resetFields();
    }
  };

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('phone', value);
    if (form.errors.phone) {
      resetFields();
    }
  };

  const handleFormSubmit = useCallback(
    async (values: StrongAuthLoginFormValues) => {
      if ((values.personalCode || values.phone) && values.personalCode && executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.StartStrongAuth);

        if (values.authMode == StrongAuthMode.MobileId) {
          startMobileIdQueryState.reset();
          mobileIdStatusQueryState.reset();
          const startMobileIdReponse = await startMobileId({
            personalCode: values.personalCode,
            phone: values.phone,
            recaptchaToken: recaptchaToken,
          });
          if ('data' in startMobileIdReponse) {
            setTempToken(startMobileIdReponse.data.tempToken);
          }
        } else {
          startSmartIdQueryState.reset();
          smartIdStatusQueryState.reset();
          startSmartId({ personalCode: values.personalCode, recaptchaToken: recaptchaToken });
        }

        setPollStartTime(new Date());
      }
    },
    [
      executeRecaptcha,
      startMobileId,
      startSmartId,
      startMobileIdQueryState,
      startSmartIdQueryState,
      smartIdStatusQueryState,
      mobileIdStatusQueryState,
    ],
  );

  const authModeChanged = useCallback(
    (newMode: StrongAuthMode) => {
      setAuthMode(newMode);
      form.setFieldValue('authMode', newMode);

      const startIdCardAsync = async () => {
        const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StartIdCard);
        startIdCardQueryState.reset();
        startIdCard({ recaptchaToken });
      };

      if (newMode == StrongAuthMode.IdCard) {
        startIdCardAsync();
      }
    },
    [form, executeRecaptcha, startIdCard, startIdCardQueryState],
  );

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        '&, & form': {
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          alignItems: 'stretch',
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        {props.titleContent ?? <></>}
        <StrongAuthSwitch modeChanged={authModeChanged}></StrongAuthSwitch>
        {authMode == StrongAuthMode.MobileId || authMode == StrongAuthMode.SmartId ? (
          <>
            <TextInput
              name="personalCode"
              label={t('landing.strongAuthLoginForm.personalCode')}
              placeholder={t('landing.strongAuthLoginForm.personalCode')}
              autoComplete="personalCode"
              error={form.errors.personalCode}
              value={form.values.personalCode}
              onChange={handlePersonalCodeChange}
              required={true}
              disabled={isLoading}
            />
            {authMode == StrongAuthMode.MobileId ? (
              <PhoneNumberInput
                name="phone"
                label={t('landing.strongAuthLoginForm.phoneNumber')}
                error={form.errors.phone}
                value={form.values.phone}
                onChange={handlePhoneNumberChange}
                required={(form.values.phone || '').length > 0}
                disabled={isLoading}
              />
            ) : (
              <></>
            )}
            {securityCode ? (
              <Box
                sx={() => ({
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                })}
              >
                <Box
                  sx={() => ({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    alignSelf: 'stretch',
                  })}
                >
                  <Box
                    sx={() => ({
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      alignSelf: 'stretch',
                    })}
                  >
                    <Text
                      sx={() => ({
                        color: 'var(--neutrals-mid-grey, #636E72)',
                        fontFeatureFettings: "'clig' off, 'liga' off",
                        fontFamily: 'ProximaNova',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '22px' /* 137.5% */,
                      })}
                    >
                      <>{t('landing.strongAuthLoginForm.securityCode')}:</>
                    </Text>
                    <Text
                      sx={() => ({
                        color: 'var(--neutrals-black, #0E1011)',
                        fontFamily: 'ProximaNova',
                        fontSize: '40px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: '48px' /* 120% */,
                      })}
                    >
                      {securityCode}
                    </Text>
                  </Box>
                  <InfoBox text={t('landing.strongAuthLoginForm.securityCodeSentMessage')}></InfoBox>
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <Button type="submit" loading={isLoading}>
              {t('landing.loginForm.login')}
            </Button>
          </>
        ) : (
          <>
            {isLoading ? (
              <Box
                sx={() => ({
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                })}
              >
                <Loader></Loader>
              </Box>
            ) : (
              <></>
            )}
            <div id="Dokobit-identity-container"></div>
          </>
        )}
        {props.footerContent ?? <></>}
      </form>
    </Box>
  );
};

export default StrongAuth;
