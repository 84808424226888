import { Box } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Complete from './Partials/complete';
import ContactConfirmation from './Partials/contactConfirmation';
import LanguageSelection from './Partials/languageSelection';
import ProfileInfo from './Partials/profileInfo';
import StrongAuth from './Partials/strongAuth';
import Progress from './Progress';
import ContactEntry from './Partials/contactEntry';
import SubHeader from '../../components/SubHeader/SubHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import useRoutes from '../../i18n/useRoutes';
import {
  setBirthDate,
  setContactTypeToConfirm,
  setHasStrongAuth,
  setCurrentStep,
  setEmail,
  setNames,
  setPhone,
  setTempToken,
  Step,
} from '../../store/profileLandingSlice';
import { ApiError, ErrorCode } from '../../models/ApiError';
import {
  useProfileLandingStartMutation,
  useProfileLandingSendConfirmationCodeMutation,
} from '../../store/api/profileLandingApi';
import RecaptchaAction from '../../models/RecaptchaAction';
import useAppLogin, { LoginParams } from '../../hooks/useAppLogin';

const StepContentRenderer = (props: {
  step: Step;
  completeLanguageSelection: () => void;
  completeStrongAuth: () => void;
  completeContactEntry: () => void;
  completeContactVerification: () => void;
  completeProfile: () => void;
  finishRegistration: () => void;
}) => {
  switch (props.step) {
    case Step.LanguageSelection:
      return <LanguageSelection completeSelection={props.completeLanguageSelection} />;
    case Step.StrongAuth:
      return <StrongAuth nextStep={props.completeStrongAuth} />;
    case Step.ContactEntry:
      return <ContactEntry completeEntry={props.completeContactEntry} />;
    case Step.ContactVerification:
      return <ContactConfirmation nextStep={props.completeContactVerification} />;
    case Step.ProfileInfo:
      return <ProfileInfo nextStep={props.completeProfile} />;
    case Step.Complete:
      return <Complete nextStep={props.finishRegistration} />;
  }
};

const ProfileLanding = () => {
  const { t } = useTranslation();
  const [sendConfirmationCode] = useProfileLandingSendConfirmationCodeMutation();
  const step = useAppSelector((state) => state.profileLanding.currentStep);
  const tempToken = useAppSelector((state) => state.profileLanding.tempToken) || '';
  const hasStrongAuth = useAppSelector((state) => state.profileLanding.hasStrongAuth);
  const contactTypeToConfirm = useAppSelector((state) => state.profileLanding.contactTypeToConfirm);
  const selectedLanguage = useAppSelector((state) => state.profileLanding.language);
  const finalAuthResponse = useAppSelector((state) => state.profileLanding.finalAuthResponse);
  const contactNeedsConfirmation = !!contactTypeToConfirm;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routes = useRoutes();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [skipContactVerification, setSkipContactVerification] = useState(true);
  const [skipStrongAuth, setSkipStrongAuth] = useState(true);
  const [login] = useAppLogin();

  const completeLanguageSelection = useCallback(() => {
    if (hasStrongAuth && !contactNeedsConfirmation) {
      dispatch(setCurrentStep(Step.ProfileInfo));
    } else if (!hasStrongAuth) {
      dispatch(setCurrentStep(Step.StrongAuth));
    } else {
      dispatch(setCurrentStep(Step.ContactEntry));
    }
  }, [dispatch, contactNeedsConfirmation, hasStrongAuth]);

  const completeStrongAuth = useCallback(() => {
    if (contactNeedsConfirmation) {
      dispatch(setCurrentStep(Step.ContactEntry));
    } else {
      dispatch(setCurrentStep(Step.ProfileInfo));
    }
  }, [contactNeedsConfirmation, dispatch]);

  const completeContactEntry = useCallback(async () => {
    if (executeRecaptcha && contactTypeToConfirm) {
      const recaptchaToken = await executeRecaptcha(RecaptchaAction.ProfileLandingSendConfirmationCode);
      sendConfirmationCode({ contactType: contactTypeToConfirm, tempToken: tempToken || '', recaptchaToken });
    }

    dispatch(setCurrentStep(Step.ContactVerification));
  }, [sendConfirmationCode, executeRecaptcha, dispatch, contactTypeToConfirm, tempToken]);

  const completeContactVerification = useCallback(() => dispatch(setCurrentStep(Step.ProfileInfo)), [dispatch]);

  const completeProfile = useCallback(() => {
    dispatch(setCurrentStep(Step.Complete));
  }, [dispatch]);

  const finishRegistration = useCallback(() => {
    if (finalAuthResponse) {
      const loginParams: LoginParams = {
        jwt: finalAuthResponse.jwt,
        personalData: finalAuthResponse.personalData,
      };

      login(loginParams);
      navigate(routes.purchaseHistory);
    } else {
      navigate(routes.login);
    }
  }, [navigate, finalAuthResponse, login, routes.login, routes.purchaseHistory]);

  const getProgressStep = (currentStep: Step): number => {
    switch (currentStep) {
      case Step.LanguageSelection:
        return 1;
      case Step.StrongAuth:
        return 2;
      case Step.ContactEntry:
        return 3;
      case Step.ContactVerification:
        return 3;
      case Step.ProfileInfo:
        return 4;
      case Step.Complete:
        return 5;
      default:
        return 1;
    }
  };

  const [start, startQueryState] = useProfileLandingStartMutation();

  const getErrorMessage = useCallback(
    (error: ApiError) => {
      if (error) {
        const errorCode = error.data?.message;
        if (error.status === 403) {
          return t('api.sessionExpiredError');
        }
        if (errorCode === ErrorCode.RecaptchaError) {
          return t('api.recaptchaError');
        }
      }

      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    dispatch(setCurrentStep(Step.LanguageSelection));
    const startCall = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const voucher = searchParams.get('voucher');
      start({ voucher: voucher ?? '' });
    };
    startCall();
  }, [dispatch, start]);

  useEffect(() => {
    if (startQueryState.isSuccess) {
      dispatch(setTempToken(startQueryState.data.tempToken));
      dispatch(setContactTypeToConfirm(startQueryState.data.contactTypeToConfirm));
      dispatch(setPhone(startQueryState.data.phone));
      dispatch(setEmail(startQueryState.data.email));
      dispatch(setBirthDate(startQueryState.data.birthDate));
      dispatch(setNames({ firstName: startQueryState.data.firstName, lastName: startQueryState.data.lastName }));
      dispatch(setHasStrongAuth(startQueryState.data.hasStrongAuth));

      setSkipContactVerification(!startQueryState.data.contactTypeToConfirm);
      setSkipStrongAuth(startQueryState.data.hasStrongAuth);

      startQueryState.reset();

      if (!!selectedLanguage) {
        completeLanguageSelection();
      }
    }
  }, [
    startQueryState,
    startQueryState.isSuccess,
    dispatch,
    startQueryState.data?.tempToken,
    startQueryState.data?.birthDate,
    startQueryState.data?.contactTypeToConfirm,
    startQueryState.data?.email,
    startQueryState.data?.firstName,
    startQueryState.data?.lastName,
    startQueryState.data?.phone,
    startQueryState.data?.hasStrongAuth,
    setSkipContactVerification,
    executeRecaptcha,
    tempToken,
    completeLanguageSelection,
    selectedLanguage,
  ]);

  useEffect(() => {
    if (startQueryState.isError && startQueryState.error) {
      const errorMessage = getErrorMessage(startQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
      navigate(routes.login);
    }
  }, [startQueryState.isError, startQueryState.error, getErrorMessage, t, navigate, routes.login]);

  const isLoading = startQueryState.isLoading;

  return (
    <Box className="container" sx={{ justifyContent: 'space-between' }}>
      <SubHeader title={t('profileLanding.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          [theme.fn.smallerThan(960)]: {
            paddingTop: 0,
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gap: theme.other.spacing(3),
            alignSelf: 'center',
            [theme.fn.smallerThan(960)]: {
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              width: '18rem',
              padding: '0 1.5rem',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '5px',
              backgroundColor: theme.colors.brandWhite,
              position: 'relative',
              [theme.fn.smallerThan(960)]: {
                width: '100%',
              },
            })}
          >
            <Progress
              currentStep={getProgressStep(step)}
              skipContactVerification={skipContactVerification}
              skipStrongAuth={skipStrongAuth}
            />
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '103px',
                position: 'absolute',
                right: '0px',
                top: '0px',
                background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 74.76%)',
                [theme.fn.smallerThan(960)]: {
                  display: 'none',
                },
              })}
            ></Box>
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '103px',
                position: 'absolute',
                right: '0px',
                bottom: '0px',
                background: 'linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 74.76%)',
                [theme.fn.smallerThan(960)]: {
                  display: 'none',
                },
              })}
            ></Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              width: '720px',
              padding: '80px 64px',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '5px',
              backgroundColor: theme.colors.brandWhite,
              position: 'relative',
              height: '100%',
              [theme.fn.smallerThan(960)]: {
                width: '100%',
                padding: '30px 20px',
              },
            })}
          >
            <Box
              sx={() => ({
                width: '100%',
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  width: '100% !important',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  [theme.fn.smallerThan(960)]: {
                    width: '100% !important',
                  },
                  '&, & form': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.fn.largerThan(500)]: {
                      alignItems: 'flex-start',
                    },
                  },
                  '& .mantine-TextInput-root': {
                    marginBottom: theme.other.spacing(1),
                    width: '100%',
                  },
                  '& .mantine-Button-root': {
                    width: '100%',
                  },
                })}
              >
                {isLoading ? (
                  <></>
                ) : (
                  <StepContentRenderer
                    step={step}
                    completeLanguageSelection={completeLanguageSelection}
                    completeStrongAuth={completeStrongAuth}
                    completeContactVerification={completeContactVerification}
                    completeContactEntry={completeContactEntry}
                    completeProfile={completeProfile}
                    finishRegistration={finishRegistration}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileLanding;
