import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import decode from 'jwt-decode';
import PersonalData from '../models/PersonalData';

export enum Step {
  LanguageSelection = 1,
  StrongAuth = 2,
  ContactEntry = 4,
  ContactVerification = 5,
  ProfileInfo = 6,
  Complete = 7,
}

export const decodeTempToken = (tempToken: string) => decode<ProfileLandingTempTokenPayload>(tempToken);

export interface ProfileLandingState {
  currentStep: Step;
  tempToken?: string | null;
  contactTypeToConfirm?: 'email' | 'phone' | null;
  hasStrongAuth?: boolean;
  personalCode?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  cardNo?: string | null;
  language?: string | null;
  finalAuthResponse?: { jwt: string; personalData: PersonalData };
}

export interface ProfileLandingTempTokenPayload {
  accountId: number;
  firstName?: string;
  lastName?: string;
  personalCode?: string;
  birthDate?: string;
  offersConsent: boolean;
  channelSms: boolean;
  channelEmail: boolean;
  email: string;
  emailConfirmed: boolean;
  phone: string;
  phoneConfirmed: boolean;
  hasPasswordAuth: boolean;
  hasStrongAuth: boolean;
  language: string;
}

const initialState: ProfileLandingState = {
  currentStep: Step.LanguageSelection,
  tempToken: null,
};

export const profileLandingSlice = createSlice({
  name: 'profileLandingSlice',
  initialState,
  reducers: {
    setCurrentStep: (state: ProfileLandingState, action: PayloadAction<Step>) => {
      state.currentStep = action.payload;
    },
    setTempToken: (state: ProfileLandingState, action: PayloadAction<string | null | undefined>) => {
      state.tempToken = action.payload;
    },
    setContactTypeToConfirm: (
      state: ProfileLandingState,
      action: PayloadAction<'email' | 'phone' | null | undefined>,
    ) => {
      state.contactTypeToConfirm = action.payload;
    },
    setHasStrongAuth: (state: ProfileLandingState, action: PayloadAction<boolean>) => {
      state.hasStrongAuth = action.payload;
    },
    setPersonalCode: (state: ProfileLandingState, action: PayloadAction<string | null | undefined>) => {
      state.personalCode = action.payload;
    },
    setBirthDate: (state: ProfileLandingState, action: PayloadAction<string | null | undefined>) => {
      state.birthDate = action.payload;
    },
    setNames: (
      state: ProfileLandingState,
      action: PayloadAction<{ firstName: string | null | undefined; lastName: string | null | undefined }>,
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setPhone: (state: ProfileLandingState, action: PayloadAction<string | null | undefined>) => {
      state.phone = action.payload;
    },
    setEmail: (state: ProfileLandingState, action: PayloadAction<string | null | undefined>) => {
      state.email = action.payload;
    },
    setLanguage: (state: ProfileLandingState, action: PayloadAction<string | null | undefined>) => {
      state.language = action.payload;
    },
    setFinalAuthResponse: (
      state: ProfileLandingState,
      action: PayloadAction<{ jwt: string; personalData: PersonalData }>,
    ) => {
      state.finalAuthResponse = action.payload;
    },
  },
});

export const {
  setCurrentStep,
  setTempToken,
  setContactTypeToConfirm,
  setHasStrongAuth,
  setPersonalCode,
  setBirthDate,
  setNames,
  setPhone,
  setEmail,
  setLanguage,
  setFinalAuthResponse,
} = profileLandingSlice.actions;
export default profileLandingSlice.reducer;
