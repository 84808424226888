import PersonalData from '../../models/PersonalData';
import { api, QueryParamKey } from '.';

export interface ProfileLandingBaseResponse {
  tempToken?: string;
}

export interface StartProfileLandingResponse extends ProfileLandingBaseResponse {
  token?: string;
  contactTypeToConfirm?: 'email' | 'phone' | null;
  birthDate?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  hasStrongAuth: boolean;
}

export interface SendConfirmationCodeResponse extends ProfileLandingBaseResponse {
  contactType: string;
}

export interface SaveProfileResponseResponse extends ProfileLandingBaseResponse {
  isComplete: boolean;
}

export interface CompleteResponseResponse extends ProfileLandingBaseResponse {
  jwt: string;
  personalData: PersonalData;
}

const profileLandingApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      profileLandingStart: builder.mutation<StartProfileLandingResponse, { voucher: string }>({
        query: ({ voucher }) => ({
          url: '/profileLanding/start',
          method: 'post',
          params: {
            voucher: voucher,
          },
        }),
      }),
      profileLandingSaveLanguage: builder.mutation<
        ProfileLandingBaseResponse,
        { language: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ language, tempToken, recaptchaToken }) => ({
          url: '/profileLanding/saveLanguage',
          method: 'post',
          params: {
            language: language,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      profileLandingSaveStrongAuth: builder.mutation<
        ProfileLandingBaseResponse,
        { strongAuthTempToken: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ strongAuthTempToken, tempToken, recaptchaToken }) => ({
          url: '/profileLanding/saveStrongAuth',
          method: 'post',
          params: {
            strongAuthTempToken: strongAuthTempToken,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      profileLandingSaveContact: builder.mutation<
        ProfileLandingBaseResponse,
        { contactType: 'email' | 'phone'; contact: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ contactType, contact, tempToken, recaptchaToken }) => ({
          url: '/profileLanding/saveContact',
          method: 'post',
          params: {
            contactType: contactType,
            contact: contact,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      profileLandingSendConfirmationCode: builder.mutation<
        SendConfirmationCodeResponse,
        { contactType: 'email' | 'phone'; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ contactType, tempToken, recaptchaToken }) => ({
          url: '/profileLanding/sendConfirmationCode',
          method: 'post',
          params: {
            contactType: contactType,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      profileLandingConfirmContact: builder.mutation<
        ProfileLandingBaseResponse,
        { contactType: 'email' | 'phone'; code: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ contactType, code, tempToken, recaptchaToken }) => ({
          url: '/profileLanding/confirmContact',
          method: 'post',
          params: {
            contactType: contactType,
            code: code,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      profileLandingSaveProfile: builder.mutation<
        SaveProfileResponseResponse,
        {
          tempToken: string;
          recaptchaToken: string | undefined;
          firstName: string;
          lastName: string;
          birthDate: string;
          offersConsent: boolean;
          channelSms: boolean;
          channelEmail: boolean;
        }
      >({
        query: ({
          tempToken,
          recaptchaToken,
          firstName,
          lastName,
          birthDate,
          offersConsent,
          channelSms,
          channelEmail,
        }) => ({
          url: '/profileLanding/saveProfile',
          method: 'post',
          body: {
            tempToken: tempToken,
            firstName,
            lastName,
            birthDate,
            offersConsent,
            channelSms,
            channelEmail,
          },
          params: {
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      profileLandingComplete: builder.mutation<
        CompleteResponseResponse,
        {
          tempToken: string;
          recaptchaToken: string | undefined;
        }
      >({
        query: ({ tempToken, recaptchaToken }) => ({
          url: '/profileLanding/complete',
          method: 'post',
          params: {
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
    };
  },
});

export const {
  useProfileLandingSendConfirmationCodeMutation,
  useProfileLandingSaveContactMutation,
  useProfileLandingConfirmContactMutation,
  useProfileLandingSaveProfileMutation,
  useProfileLandingStartMutation,
  useProfileLandingSaveStrongAuthMutation,
  useProfileLandingCompleteMutation,
  useProfileLandingSaveLanguageMutation,
} = profileLandingApi;

export default profileLandingApi.reducer;
